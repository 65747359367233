<template>
  <div
    v-if="appConfig.VueSettingsPreRun.MenuItemLayout === 'Gondor'"
    class="Gondor v-nomenclature-card v-slot-lunch-card"
    :class="{
      'v-horizontal-mobile-card': isSmall && imageExists(productInList.ProductMobileImage)
    }"
    data-test-id="slot-lunch-card"
  >
    <div
      :key="productInList.ID"
      class="v-menu-item-image v-pointer v-mb-xs"
      itemscope
      data-test-id="slot-lunch-card-item-image"
      itemtype="http://schema.org/ImageObject"
      @click="async () => await cardClick()"
    >
      <menu-stickers :stickers="productInList.StickerTags" />
      <arora-nuxt-image
        :key="productInList.ID"
        :image="
          isSmall && imageExists(productInList.ProductMobileImage)
            ? productInList.ProductMobileImage
            : productInList.SmallImage
        "
        :image-type="
          isSmall && imageExists(productInList.ProductMobileImage) ? 'ProductMobile' : 'ProductSmall'
        "
        :alt="productInList.Name"
        itemprop="image"
      />
      <div
        v-if="accountStore.isLoggedIn"
        class="v-slot-lunch-card-favorite"
      >
        <menu-product-favorite-icon
          :is-favorite="productInList.IsFavorite"
          @click.stop="async () => await accountStore.addOrRemoveFromFavorites(productInList)"
        />
      </div>
    </div>

    <div class="v-nomenclature-card-summary v-slot-lunch-card-summary">
      <div class="v-nomenclature-card-summary-top v-slot-lunch-card-summary-top">
        <div class="v-title-wrapper v-mb-xs">
          <div class="v-nomenclature-card-title v-slot-lunch-card-title">
            <common-popover
              v-if="
                !stringIsNullOrWhitespace(
                  productInList.NoOrderTypeReasonText || productInList.PersonalProductMessage
                )
              "
              class="v-info-nomenclature v-mr-xs"
            >
              <template #hoverable>
                <span> <icon-menu-info /></span>
              </template>
              <template #content>
                <div v-html="sanitize(productInList.PersonalProductMessage)" />
                <div v-html="sanitize(productInList.NoOrderTypeReasonText)" />
              </template>
            </common-popover>
            <arora-nuxt-link
              class-name="v-slot-lunch-card-title-link v-title"
              :disabled="allOptionsInStopList"
              :href="productInList.Link"
              :label="sanitize(productInList.Name)"
              itemprop="url"
            />
          </div>
        </div>
        <menu-description :product-in-list="productInList" />
      </div>
      <menu-card-available-by-order-type-text :product-in-list="productInList" />
      <div class="v-slot-lunch-card-summary-bottom">
        <div
          v-if="objectKeys(productInList.Options).length > 0"
          class="v-option"
          data-test-id="product-option"
        >
          <menu-option-selector
            :options="productInList.Options"
            :product="productInList"
            :threshold="appConfig.VueSettingsPreRun.MenuItemsOptionThreshold"
            data-test-id="product-card-options"
            theme="product-card"
          />
        </div>
        <div
          v-if="isProductUnavailable(productInList)"
          class="v-flex-100 v-d-flex v-flex-row"
        >
          <div
            v-if="menuStore.ProductsInStopList.includes(productInList.ID)"
            class="v-nomenclature-card-in-stop-list v-product-in-stop-list"
            data-test-id="slot-lunch-card-in-stop-list"
          >
            <span v-html="translate('productCard.inStopListButton')" />
          </div>
          <common-popover
            v-else
            class="v-w-100"
          >
            <template #hoverable>
              <div
                class="v-nomenclature-card-in-stop-list v-product-in-stop-list"
                data-test-id="slot-lunch-card-unavailable-by-order-type"
              >
                <span v-html="translate('productCard.notAvailableByOrderTypeButton')" />
              </div>
            </template>
            <template #content>
              <span
                v-html="
                  clientStore.orderTypeNotSelected
                    ? translate('productCard.notAvailableByOrderType')
                    : translate('productCard.notAvailableByOrderTypeTooltip', {
                        orderType: translate(clientStore.orderTypeText)
                      })
                "
              />
            </template>
          </common-popover>
        </div>
        <div
          v-else
          class="v-flex-100 v-d-flex v-flex-row v-justify-content-between v-align-items-center"
        >
          <menu-product-in-list-price-and-button
            :card-click="cardClick"
            :product-in-list="productInList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Option } from '~types/menuStore'
import type { AnyProductCard } from '~types/props'

import { useCommon, useWindowSize } from '@arora/common'

const { productInList } = defineProps<
  AnyProductCard & {
    disableLazy: boolean
  }
>()

const accountStore = useAccountStore()
const clientStore = useClientStore()

const appConfig = useAppConfig()
const { stringIsNullOrWhitespace, objectKeys } = useCommon()
const { imageExists } = useImageInfo()
const { isSmall } = useWindowSize()
const { isProductUnavailable } = useProduct()

const { sanitize, translate } = useI18nSanitized()
const menuStore = useMenuStore()

const allOptionsInStopList = computed<boolean>(() => {
  const options = ([] as Option[]).concat.apply([], Object.values(productInList.Options))

  return (
    menuStore.ProductsInStopList.includes(productInList.ID) ||
    (options.length > 0 && options.every((option) => menuStore.OptionsInStopList.includes(option.ID)))
  )
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-slot-lunch-card {
  .v-slot-lunch-card-summary-top {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .v-title-wrapper {
    .v-slot-lunch-card-title {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .v-slot-lunch-card-title-link {
      color: variables.$ProductCardColor;
      font-weight: 600;
      font-size: 1.25rem;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-summary-bottom {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    .v-stepper-counter-horizontal,
    .v-btn {
      flex: 0 1 auto;
      width: auto;
    }
  }
}
</style>
